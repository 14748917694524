import React from "react";
import axios from "../request";

export function CompModal(props) {

  const options = ["Nyílt", "Ifi B", "Ifi A", "Junior", "Felnőtt", "Szenior", "Serdülő", "Gyerek", "Mini", "Szupermini"];
  const compOptions=  ["2025 Buda & Zugló", "Teszt"];

  const initialState = {
    category: options[0],
    compName: compOptions[0],
    points: 0,
    date: new Date().toISOString(),
  }

  const [{
    category,
    compName,
    points,
    date,
  }, setState ] = React.useState(initialState);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }

  function reset() {
    setState({ ...initialState });
  }

  function toggleModal() {
    reset();
    props.toggleModal();
  }

  function saveCompResult() {
    return axios.post('/add-comp-result', {
      _id: props.user._id,
      competition: {
        date: new Date(date).toISOString(),
        name: compName,
        points,
        category,
      },
    })
      .then(props.getUsers)
      .then(() => {
        reset();
      })
      .catch(console.error);
  }

  return (
    <div className="add-new modal" style={{"display": props.modal ? "flex" : "none"}} onClick={toggleModal}>
      <div className="modal-content" onClick={stopPropagation}>
        <div className="">
          <h2>{props.user.name}</h2>
        </div>
        <div className="">
          <h3>{props.user.email}</h3>
        </div>
        <div className="row">
          <div className="column modal-margin">
            <label htmlFor="date">Versenynap</label>
            <input id="date" name="date" value={date} type="date" onChange={handleChange} />
          </div>
          <div className="column modal-margin">
            <label htmlFor="compName">Verseny</label>
            <select id="compName" name="compName" value={compName} onChange={handleChange}>
              {
                compOptions.map(option => {
                  return (
                    <option key={option} value={option}>{option}</option>
                  );
                })
              }
            </select>
          </div>
          <div className="column modal-margin">
            <label htmlFor="points">Pontszám</label>
            <input id="points" name="points" type="text" value={points} onChange={handleChange} />
          </div>
          <div className="column modal-margin">
            <label htmlFor="category">Kategória</label>
            <select id="category" name="category" value={category} onChange={handleChange}>
              {
                options.map(option => {
                  return (
                    <option key={option} value={option}>{option}</option>
                  );
                })
              }
            </select>
          </div>
        </div>
        <div className="button-item">
          <button onClick={saveCompResult}>Eredmény mentése</button>
        </div>
        <div>
          {
            props.user.competitions && Object.keys(props.user.competitions).map(competition => {
              return (
                <div className="row" key={competition}>
                  <div className="row-item">{`${props.user.competitions[competition].year}-${props.user.competitions[competition].month}-${props.user.competitions[competition].day}`}</div>
                  <div className="row-item">{props.user.competitions[competition].name}</div>
                  <div className="row-item">{props.user.competitions[competition].points}</div>
                  <div className="row-item">{props.user.competitions[competition].category}</div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
