import React from "react";
import { CompUser } from "../components/comp-user";
import { UserSearch } from "../components/search";
import { Pager } from "../components/pager";
import { Navigation } from "../components/navigation";

export function Competition(props) {
  return (
    <div>
      <button onClick={props.logoutHandler}>Kijelentkezés</button>
      <Navigation />
      <div className="page-header">
        <h1>Verseny Eredmények</h1>
      </div>
      <div>
        <UserSearch setSearch={props.setSearch} />
        <div className="comp-page">
          <div className="row">
            <span className="row-item">Név</span>
            <span className="row-item">Email</span>
            <span className="row-item"></span>
          </div>
          {
            props.users && props.users.map(user => {
              return (
                <CompUser language={props.language} user={user} key={user._id} getUsers={props.getUsers} />
              );
            })
          }
          <Pager limit={props.limit} skip={props.skip} setSkip={props.setSkip} count={props.count} />
        </div>
      </div>
    </div>
  );
}
