import React from "react";
import { CompModal } from "./comp-modal";

export function CompUser(props) {
  const initialState = {
    name: props.user.name,
    email: props.user.email,
    modal: false,
  }

  const [{ name, email, modal }, setState] = React.useState(initialState);

  function toggleModal() {
    setState(prevState => ({ ...prevState, modal: !prevState.modal }));
  }

  return (
    <div className="row user-list-row">
      <div className="row-item">
        <input disabled name="name" value={name} />
      </div>
      <div className="row-item">
        <input disabled value={email} type="email" inputMode="email" />
      </div>
      <div className="row-item">
        <button onClick={toggleModal}>+ Új eredmény</button>
      </div>
      <CompModal modal={modal} toggleModal={toggleModal} user={props.user} getUsers={props.getUsers}></CompModal>
    </div>
  );
}
